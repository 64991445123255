<template>
  <div class="contractlist bj">
    <div class="title">
      <div class="form jn-form-style">
        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
        </div>
        <div class="form-item">
          <el-select
            placeholder="选择集配中心"
            v-model="formData.logistic_business_id"
            clearable
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.type"
            clearable
            placeholder="请选择合同类型"
          >
            <el-option label="供应商" value="1"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择状态"
          >
            <el-option label="签约中" value="1"></el-option>
            <el-option label="已停止" value="0"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.name"
            placeholder="请输入合同名称"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-input
            v-model="formData.create_user"
            placeholder="请输入创建人"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-date-picker
            v-model="formData.create_time"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="创建时间起"
            end-placeholder="创建时间止"
          >
          </el-date-picker>
        </div>

        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button type="warning" @click="contractlistlistexport"
          >导 出</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="logisticsName" align="center" label="集配中心">
          <template slot-scope="scope">
            {{ scope.row.business.name || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contractlist_type_txt"
          align="center"
          label="合同类型"
        >
          <template>
            <div>供应商</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="合同名称">
        </el-table-column>
        <el-table-column prop="create_user" align="center" label="创建人">
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间">
        </el-table-column>
        <el-table-column prop="file_url" align="center" label="合同模版">
            <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.file_url" @click="infopath(scope.row.file_url)">点击查看</el-button>
            </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="合同状态">
          <template slot-scope="scope">
            <div>{{ scope.row.status == 1 ? "签约中" : "已停止" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="stopclcik(scope.row)"
              >停止签约</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
  <script>
import cloneDeep from "lodash/cloneDeep";
import { contractexport } from "@/api/export/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "contractlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,

      formData: {
        logistic_business_id: "", //	T文本	是	集配中心id
        type: "", //	T文本	是	类型，1-供应商
        name: "", //	T文本	是	合同名称
        create_user: "", //	T文本	是	创建人
        status: "", //	T文本	是	状态：1-签约中，0-已停止
        create_time: [], //	T文本	是	[2025-01-01,20-2025-01-1]
        page: 1,
        pageSize: 50,
      }, // 搜索条件
      formDataClone: {}, // 复制一份，用于初始化
      currentPage: 0,
      tableData: [],
      total: 0,

      // 集配中心列
      logisticsList: [],
    };
  },

  mounted() {
    this.formDataClone = cloneDeep(this.formData);
    this.getTableData();
    this.getAjaxLogisticsList();
  },
  methods: {
    infopath(e){
        
window.open(e,"_blank")
    },
    stopclcik(e) {
      this.$confirm("确定要停止签约?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.contract.getcontractstop({ id: e.id }).then((res) => {
            this.$message({
              type: "success",
              message: "停止签约成功!",
            });
            this.getTableData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消停止签约",
          });
        });
    },
    //导出
    contractlistlistexport() {
      let params = {
        ...this.formData,
      };
      delete params.page;
      delete params.pageSize;

      contractexport(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },

    /**
     * 搜索了列表数据
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },

    /**
     * 重置表单
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.currentPage = 1;
      this.getTableData();
    },

    contractlistSupplierList() {
      this.$api.general
        .contractlistSupplierList(this.formChildData)
        .then((res) => {
          console.log(res, "获取数据");
          this.listData = res.data.data;
          this.total = res.data.total;
        });
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.formData.page = val;
      this.currentPage = val;
      this.getTableData();
    },

    /**
     * 获取列表数据
     */
    getTableData() {
      this.loading = true;
      const params = {
        ...this.formData,
      };
      this.$api.contract.getcontractlist(params).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
  <style lang="scss">
.contractlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
  